const app = {
  baseURL: "https://prod.tas-online.live/",
  // baseURL: "https://prod.tas.online/",
  VUE_APP_SECRET_KEY: "TAS_2024@@",
  VUE_APP_MODE: "production",
  VITE_CUSTOMER_PORTAL_URL: "https://tas-ai.customers.subsbase.com/",
  VITE_SITE_ID: "tas-ai",

};
//https://back.preprod-tas.com/
//https://prod.tas-online.live/
//https://production.tas-technologies.com/

export default app;
